/* eslint-disable */
import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    Sabem que aquest any us presenteu a les policies locals, i també sabeu que en
                    les entrevistes <strong>els casos pràctics i els role plays són CLAU</strong>.
                </p>

                <p>
                    💥 A <strong>iOpos</strong> som especialistes en aquesta prova, i venim a
                    ajudar-te a marcar la diferència.
                </p>

                <p>
                    <strong>En què consisteix aquesta prova?</strong>
                </p>

                <p>Te la resumim en 10 segons:</p>

                <ul>
                    <li>Et poden cridar.</li>
                    <li>
                        Pots haver d’interactuar amb una persona sense sostre, un delinqüent o en
                        qualsevol situació límit.
                    </li>
                    <li>
                        Hauràs de proposar solucions, mostrar un ampli ventall de respostes i
                        gestionar-ho amb intel·ligència emocional.
                    </li>
                </ul>

                <p>
                    👉{" "}
                    <strong>En definitiva, hauràs de resoldre una situació en temps real.</strong>
                </p>

                <p>
                    <strong>Per què aquest curs és per a tu?</strong>
                </p>

                <ul>
                    <li>
                        🔝 Torna un dels cursos estrella d’iOpos, amb el famós (i temut){" "}
                        <strong>ROLE PLAY</strong>.
                    </li>
                    <li>
                        🎯 Entrenaràs en grup reduït, amb dos docents de referència, en un escenari
                        d’immersió total, com si estiguessis a l’entrevista real.
                    </li>
                </ul>

                <p>
                    <strong>📅 Data:</strong> Diumenge 20 d’abril
                    <br />
                    <strong>🕘 Horari:</strong> De 9:00 a 14:00 h
                </p>

                <p>
                    🚨 <strong>Places molt limitades!</strong>
                </p>

                <p>
                    <strong>💰 Preu:</strong> 89,99 €
                </p>

                <p>
                    Si vols arribar segur/a a la teva entrevista,{" "}
                    <strong>no t’ho pensis més</strong>.
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="🎭 Domina la prova més imprevisible de l’entrevista: entrena el role play amb experts i guanya confiança per destacar davant el tribunal."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
